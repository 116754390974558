var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("custom-data-table", {
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          "fixed-filters": { driver: _vm.$route.params.id },
          "per-page": 50,
          query: _vm.fleet_operations_list,
          sort: [["created_at", "DESC"]],
          "query-node": "fleetOperations",
        },
        on: { "vuetable:row-clicked": _vm.$_goTo },
        scopedSlots: _vm._u([
          {
            key: "car",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " + _vm._s(props.rowData.car.license_plate || "-") + " "
                ),
              ])
            },
          },
          {
            key: "lock_reason",
            fn: function (props) {
              return _c("lock-reasons-enum", {
                attrs: { reason: props.rowData.lock_reason || "" },
              })
            },
          },
          {
            key: "lock_request_by",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " + _vm._s(props.rowData.lock_request_by || "-") + " "
                ),
              ])
            },
          },
          {
            key: "created_at",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("format_date_tz")(
                        props.rowData.created_at,
                        "DD/MM/YYYY HH:mm"
                      )
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "unlock_reason",
            fn: function (props) {
              return _c("unlock-reasons-enum", {
                attrs: { reason: props.rowData.unlock_reason || "" },
              })
            },
          },
          {
            key: "unlock_request_by",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " + _vm._s(props.rowData.unlock_request_by || "-") + " "
                ),
              ])
            },
          },
          {
            key: "updated_at",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm._f("format_date_tz")(
                        props.rowData.updated_at,
                        "DD/MM/YYYY HH:mm"
                      )
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "status",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c("block-histories-status-badge", {
                    attrs: { status: props.rowData.status },
                  }),
                ],
                1
              )
            },
          },
        ]),
      }),
      _c("modal-block-history-events", {
        attrs: { "fleet-operation-status": _vm.fleetOperationStatusObject },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }